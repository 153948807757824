export class Stack {
    constructor() {
        this.#stack = [];
    }

    push(item) {
        this.#stack.push(item);
    }

    pop() {
        if (this.#stack.length == 0) {
            throw "pop called on empty stack";
        }
        return this.#stack.pop();
    }

    at(index) {
        if (index < 0 || this.#stack.length <= index) {
            throw "stack index out of range";
        }

        return this.#stack[this.#stack.length - index - 1];
    }

    remove(index) {
        if (index < 0 || this.#stack.length <= index) {
            throw "stack index out of range";
        }

        const value = this.#stack[this.#stack.length - index - 1];
        this.#stack.splice(this.#stack.length - index - 1, 1);
        return value;
    }

    clear() {
        this.#stack = [];
    }

    get_as_list() {
        return [...this.#stack];
    }

    #stack;
}
