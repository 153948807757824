<template>
    <div>
        <h1>Comp game</h1>
        <button @click="is_game_description_visible=!is_game_description_visible">Show/hide game description</button>
        <br v-if="is_game_description_visible"/>
        <br v-if="is_game_description_visible"/>
        <GameDescription v-if="is_game_description_visible"/>
        <br/>
        <br/>
        <button @click="is_tasks_visible=!is_tasks_visible">Show/hide tasks</button>
        <br/>
        <br/>
        <GameTasks v-if="is_tasks_visible"/>
        <br v-if="is_tasks_visible"/>
        <br v-if="is_tasks_visible"/>
        <GameUI/>
    </div>
</template>

<script setup>
import {ref} from "vue"
import GameDescription from "./GameDescription.vue"
import GameTasks from "./GameTasks.vue"
import GameUI from "./GameUI.vue"
let is_game_description_visible = ref(false);
let is_tasks_visible = ref(false);
</script>

<style scoped>
</style>
