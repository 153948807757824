<template>
  <div class="main-container">
    <h1>TASKS</h1>
    <div class="cards-container">
      <div
        v-for="task in tasks"
        :key="task"
        class="card"
        :class="{
          'card-easy': is_level_easy(task.level),
          'card-medium': is_level_medium(task.level),
          'card-hard': is_level_hard(task.level),
          'card-very-hard': is_level_very_hard(task.level),
        }"
      >
        <div class="card-id">
          <h1>{{ task.id }}</h1>
        </div>
        <div class="card-info">
          <h3>
            {{ task.description }}
          </h3>
          <div class="card-info-params">
            <p>
              Memory size: {{ task.memory_size }}, max cycle:
              {{ task.max_cycle }}
            </p>
          </div>
          <div v-for="image in task.images" :key="image">
            <img :src="get_image_src(image)" />
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const tasks = [
  {
    id: "1",
    level: 1,
    description: "Create the following output: '7'",
    memory_size: 10,
    max_cycle: 300,
  },
  {
    id: "2",
    level: 1,
    description: "Create the following output: '35'",
    memory_size: 10,
    max_cycle: 300,
  },
  {
    id: "3",
    level: 1,
    description: "Create the following output: 'Milan'",
    memory_size: 10,
    max_cycle: 300,
  },
  {
    id: "4A",
    level: 1,
    description: "Create the following output: 'abcdefghijklmnopqrstuvwxyz'",
    memory_size: 10,
    max_cycle: 1000,
  },
  {
    id: "4B",
    level: 2,
    description:
      "Create the following output: 'abcdefghijklmnopqrstuvwxyz' using at most 40 commands",
    memory_size: 10,
    max_cycle: 1000,
  },
  {
    id: "4C",
    level: 3,
    description:
      "Create the following output: 'abcdefghijklmnopqrstuvwxyz' using at most 30 commands",
    memory_size: 10,
    max_cycle: 1000,
  },
  {
    id: "4D",
    level: 4,
    description:
      "Create the following output: 'abcdefghijklmnopqrstuvwxyz' using at most 20 commands",
    memory_size: 10,
    max_cycle: 1000,
  },
  {
    id: "5A",
    level: 1,
    description:
      "The first 2 cells of the memory contains 2 numbers. Print the higher one",
    memory_size: 10,
    max_cycle: 200,
  },
  {
    id: "5B",
    level: 1,
    description: "Coming soon",
    memory_size: 10,
    max_cycle: 200,
  },
  {
    id: "5C",
    level: 2,
    description: "Coming soon",
    memory_size: 10,
    max_cycle: 200,
  },
  {
    id: "5D",
    level: 2,
    description: "Coming soon",
    memory_size: 10,
    max_cycle: 200,
  },
  {
    id: "6",
    level: 1,
    description:
      "The first 16 cells of the memory are filled with numbers. Print the lowest index that contains a 0",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "7A",
    level: 2,
    description:
      "The first 16 cells of the memory are filled with numbers. Print the highest one of them",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "7B",
    level: 3,
    description:
      "The first 16 cells of the memory are filled with numbers. Print the highest one of them. Use at most 29 commands",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "8A",
    level: 1,
    description:
      "Implement the modulo (%) operator (remainder of integer division). The 0th memory cell contains the number, the 1st cell contains the modulus. Examples: 5%2=1, 6%3=0",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "8B",
    level: 2,
    description: "Implement the modulo (%) operator using at most 30 commands.",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "9",
    level: 3,
    description:
      "Implement the XOR operation. The input numbers will be in the first 2 cells of the memory",
    memory_size: 20,
    max_cycle: 1000,
  },
  {
    id: "10",
    level: 3,
    description:
      "You are given 16 numbers (in memory). One of them is a duplicate. Find and print its value",
    memory_size: 20,
    max_cycle: 10000,
  },
  {
    id: "10",
    level: 2,
    description:
      "You are given 15 numbers (in memory). 7 numbers appear twice, and a single one only once. Find and print the single one",
    memory_size: 20,
    max_cycle: 10000,
  },
  {
    id: "11",
    level: 3,
    description:
      "You are given 15 numbers (in memory). They are from a range from X to X + 15 (eg. 12 to 27). 1 number is missing. Print the missing number",
    memory_size: 20,
    max_cycle: 10000,
  },
  {
    id: "18",
    level: 4,
    description:
      "Your task is to implement the 'fill' feature of microsoft paint. It is denoted by a paint bucket icon. The image contains 16 rows and 16 columns. " +
      "Each pixel is stored in memory, in the first 256 memory cells. (0, 0) pixel of the image is in memory cell 0. (0, 1) pixel is in memory cell 1. " +
      "And so on... Each pixel is either black (1) or white (0). " +
      "An example image is given below. The fill is initiated from the top left pixel of the image (at (0, 0) coordinates). " +
      "The paint overrides the corresponding 0 values to 2 in memory. The expected (resulting) image for the fill is also shown here.",
    memory_size: 2000,
    max_cycle: 10000,
    images: ["task_paint_before.png", "task_paint_after.png"],
  },
  {
    id: "19",
    level: 4,
    description:
      "Your task is to implement a calculator. The expression is stored in the first cells of the memory as a '0' terminated string. " +
      "The program processes the input and its output should be the calculated result. " +
      "The following operators should be supported: '+', '-', '*', and '/'. Parentheses should also be considered. " +
      "An example input: '14+3*(1-4)'. The expected output for this expression would be '5'.",
    memory_size: 2000,
    max_cycle: 10000,
  },
  {
    id: "20A",
    level: 4,
    description:
      "Your task is to find a path in a labyrinth between 2 points. The labyrinth is given by its height and width (first 2 memory cells). " +
      "All of the following cells in memory represent a pixel of the labyrinth (either empty (0) or wall (1)). " +
      "For this task, you need to find a path from the top left corner (0, 0) to the bottom right corner of the labyrinth. " +
      "Your output should be a string containing 'U', 'R', 'D', 'L' characters, representing the direction taken at each position in the labyrinth. " +
      "There might be multiple paths, you can print any of them.",
    memory_size: 2000,
    max_cycle: 10000,
    images: ["labyrinth.png"],
  },
  {
    id: "20B",
    level: 4,
    description: "Coming soon (Labyrinth)",
    memory_size: 2000,
    max_cycle: 10000,
  },
];

function get_image_src(image_file_name) {
  return "/images/" + image_file_name;
}

function is_level_easy(level) {
  return level == 1;
}

function is_level_medium(level) {
  return level == 2;
}

function is_level_hard(level) {
  return level == 3;
}

function is_level_very_hard(level) {
  return level == 4;
}
</script>

<style scoped>
.main-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  border: 1px solid black;
  gap: 10px;
}

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.card {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 0px 10px;
  gap: 10px;
}

.card-easy {
  background: radial-gradient(#60efbc, #58d5c9);
}

.card-medium {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.card-hard {
  background: radial-gradient(#76b2fe, #b69efe);
}

.card-very-hard {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.card:hover {
  background-color: lightgray;
}

.card-id {
  min-width: 80px;
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.card-info-params {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
