import {Stack} from "./stack.js"
import {Memory} from "./memory.js"


export class Game {
    constructor(memory_size) {
        this.#command_map = {
            ' ': () => { this.#program_counter++; },
            '0': () => { this.#stack.push(0); this.#program_counter++; },
            '1': () => { this.#stack.push(1); this.#program_counter++; },
            '2': () => { this.#stack.push(2); this.#program_counter++; },
            '3': () => { this.#stack.push(3); this.#program_counter++; },
            '4': () => { this.#stack.push(4); this.#program_counter++; },
            '5': () => { this.#stack.push(5); this.#program_counter++; },
            '6': () => { this.#stack.push(6); this.#program_counter++; },
            '7': () => { this.#stack.push(7); this.#program_counter++; },
            '8': () => { this.#stack.push(8); this.#program_counter++; },
            '9': () => { this.#stack.push(9); this.#program_counter++; },
            '+': () => { this.#stack.push(this.#stack.pop() + this.#stack.pop()); this.#program_counter++; },
            '-': () => { const a = this.#stack.pop(); const b = this.#stack.pop(); this.#stack.push(b - a); this.#program_counter++; },
            '*': () => { this.#stack.push(this.#stack.pop() * this.#stack.pop()); this.#program_counter++; },
            '/': () => { const a = this.#stack.pop(); if (a == 0) { throw "Division by zero"; } const b = this.#stack.pop(); this.#stack.push(Math.floor(b / a)); this.#program_counter++; },
            'p': () => { this.#output += this.#stack.pop(); this.#program_counter++; },
            'P': () => { this.#output += String.fromCharCode(this.#stack.pop() & 0x7F); this.#program_counter++; },
            ':': () => { const a = this.#stack.pop(); const b = this.#stack.pop(); if (b < a) {this.#stack.push(-1);} else if (b == a) {this.#stack.push(0);} else {this.#stack.push(1);} this.#program_counter++; },
            'g': () => { this.#program_counter += this.#stack.pop(); },
            '?': () => { const offset = this.#stack.pop(); if (this.#stack.pop() == 0) { this.#program_counter += offset; } else { this.#program_counter++ } },
            '<': () => { this.#stack.push(this.#memory.read(this.#stack.pop())); this.#program_counter++; },
            '>': () => { this.#memory.write(this.#stack.pop(), this.#stack.pop()); this.#program_counter++; },
            '^': () => { const index = this.#stack.pop(); this.#stack.push(this.#stack.at(index)); this.#program_counter++; },
            'v': () => { const index = this.#stack.pop(); this.#stack.push(this.#stack.remove(index)); this.#program_counter++; },
            '!': () => { this.#is_finished = true; },
        };
        this.#memory = new Memory(memory_size);
        this.#stack = new Stack();
        this.#call_stack = new Stack();
    }

    simulate(initial_memory, cycle_limit, command_str) {
        this.#cycle_limit = cycle_limit;
        this.#stack.clear();
        this.#call_stack.clear();
        this.#memory.init_values(initial_memory);
        this.#output = "";
        this.#result = [];
        this.#program_counter = 0;
        this.#is_finished = false;

        let error_message = "";
        try {
            this.execute_commands(command_str);
        } catch (err) {
            error_message = err;
        }

        return [this.#result, error_message];
    }

    execute_commands(command_str) {
        let execution_counter = 0;
        while (execution_counter < this.#cycle_limit && !this.#is_finished) {
            if (this.#program_counter < 0) {
                throw "program counter < 0";
            }
            if (command_str.length <= this.#program_counter) {
                throw "program counter is out of bounds. Use '!' to stop the execution.";
            }
            const command = command_str[this.#program_counter];
            if (!this.#command_map[command]) {
                throw "invalid command found!";
            }
            let program_counter_before = this.#program_counter;
            execution_counter++;
            this.#command_map[command]();
            this.#result.push(this.create_execution_result(command_str, execution_counter, program_counter_before, this.#memory.get_as_list(), this.#stack.get_as_list(), this.#output));
        }

        if (execution_counter == this.#cycle_limit) {
            throw "max cycle reached!";
        }
    }

    create_execution_result(command, index, program_counter, memory, stack, output) {
        return {
            command: command,
            index: index,
            program_counter: program_counter,
            memory: memory,
            stack: stack,
            result: output
        };
    }

    #cycle_limit;
    #command_map;
    #program_counter;
    #output;
    #stack;
    #call_stack;
    #memory;
    #result;
    #is_finished;
}
