export class Memory {
    constructor(size) {
        this.#memory = new Array(size);
    }

    init_values(values) {
        for (let i = 0; i < this.#memory.length; i++) {
            if (i < values.length) {
                this.#memory[i] = values[i];
            } else {
                this.#memory[i] = 0;
            }
        }
    }

    read(index) {
        if (index < 0 || this.#memory.length <= index) {
            throw "memory index out of range";
        }

        return this.#memory[index];
    }

    write(index, value) {
        if (index < 0 || this.#memory.length <= index) {
            throw "memory index out of range";
        }

        this.#memory[index] = value;
    }

    get_as_list() {
        return [...this.#memory];
    }

    #memory;
}
