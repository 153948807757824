<template>
    <div class="main-container">
        <div class="row-container">
            <h4>Init memory:</h4>
            <input type="text" v-model="data.initial_memory" placeholder="1,2,3" v-on:keydown.enter="execute_command()" />
        </div>
        <div class="row-container">
            <h4>Command:</h4>
            <input type="text" v-model="data.command" v-on:keydown.enter="execute_command()" />
            <button @click="execute_command">RUN</button>
        </div>
        <table>
            <tr>
                <th>Index</th>
                <th>Program counter</th>
                <th>Executed command</th>
                <th>Memory</th>
                <th>Stack</th>
                <th>Output</th>
            </tr>
            <tr v-for="execution_result of data.execution_results" :key="execution_result">
                <td>{{ execution_result.index }}</td>
                <td>{{ execution_result.program_counter }}</td>
                <td>{{ execution_result.command.slice(0, execution_result.program_counter) }}<span
                        style="color: red">[{{ execution_result.command[execution_result.program_counter] }}]</span>{{
                                execution_result.command.slice(execution_result.program_counter
                                    + 1)
                        }}</td>
                <td>{{ execution_result.memory }}</td>
                <td>{{ execution_result.stack }}</td>
                <td>'{{ execution_result.result }}'</td>
            </tr>
        </table>
        <h2 v-if="data.error_str">Error: {{ data.error_str }}</h2>
        <h2 v-if="!data.error_str">Output: '{{ data.output }}'</h2>
    </div>
</template>

<script setup>
import { Game } from "../game.js"
import { reactive } from "vue"

let data = reactive({
    command: "",
    initial_memory: "",
    execution_results: [],
    error_str: "",
    output: ""
});

const cycle_limit = 1000;

const game = new Game(20);

function execute_command() {
    [data.execution_results, data.error_str] = game.simulate(convert_str_to_list(data.initial_memory), cycle_limit, data.command);
    data.output = "";
    if (!data.error_str && 0 < data.execution_results.length) {
        data.output = data.execution_results[data.execution_results.length - 1].result;
    }
}

function convert_str_to_list(str) {
    // split by ','
    // remove trailing spaces
    // remove empty items
    // convert to int
    const result = str.split(',').map(element => element.trim()).filter(element => element).map(element => parseInt(element));
    if (result.includes(NaN)) {
        return [];
    }
    return result;
}
</script>

<style scoped>
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    border: 1px solid black;
    background-color: lightblue;
}

.row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 10px 10px 10px;
    border: 1px solid black;
}

input {
    flex-grow: 1;
    font-size: 25px;
}

table,
th,
td {
    border: 1px solid black;
}
</style>
