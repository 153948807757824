<template>
    <div class="main-container">
        <div class="info-block">
            <h1>STACK</h1>
            <p>
                In computer science, a stack is an abstract data type that serves as a collection of elements, with two
                main operations:
                <ul>
                <li>Push, which adds an element to the collection, and</li>
                <li>Pop, which removes the most recently added element that was not yet removed</li>
            </ul>
            </p>
        </div>
        <div class="info-block">
            <h1>MEMORY</h1>
            <p>
                The memory is used to store data for a computer.
                The memory is divided into large number of small parts called cells.
                Each location or cell has a unique address, which varies from zero to memory size minus one.
            </p>
        </div>
        <div class="info-block">
            <h1>PROGRAM COUNTER</h1>
            <p>
                The program counter (PC) is a processor register that indicates where a computer is in its program sequence.
                Usually, the PC is incremented after fetching an instruction, and holds the memory address of ("points to") the next instruction that would be executed.
                Processors usually fetch instructions sequentially from memory, but control transfer instructions change the sequence by placing a new value in the PC.
            </p>
        </div>
        <div class="info-block">
            <h1>INSTRUCTIONS</h1>
            <p>
                Every instruction pop its operands from the stack.
                S[0] is the top, S[1] is the 2nd top item of the stack.
                <br />
                <br />
                List of instructions:
            <table>
                <tr>
                    <th>Instruction</th>
                    <th>Description</th>
                    <th>Stack before</th>
                    <th>Stack after</th>
                    <th>Memory before</th>
                    <th>Memory after</th>
                    <th>Output</th>
                </tr>
                <tr v-for="instruction of instructions" :key="instruction">
                    <td>{{ instruction[0] }}</td>
                    <td>{{ instruction[1] }}</td>
                    <td>{{ instruction[2] }}</td>
                    <td>{{ instruction[3] }}</td>
                    <td>{{ instruction[4] }}</td>
                    <td>{{ instruction[5] }}</td>
                    <td>{{ instruction[6] }}</td>
                </tr>
            </table>
            </p>
        </div>
    </div>
</template>

<script setup>
const instructions = [
    ["' '", "Do nothing", "[1, 2]", "[1, 2]", "[0, 0]", "[0, 0]", "''"],
    ["'0'", "Push value 0 to the stack", "[1, 2]", "[1, 2, 0]", "[0, 0]", "[0, 0]", "''"],
    ["'1'", "Push value 1 to the stack", "[1, 2]", "[1, 2, 1]", "[0, 0]", "[0, 0]", "''"],
    ["'2'", "Push value 2 to the stack", "[1, 2]", "[1, 2, 2]", "[0, 0]", "[0, 0]", "''"],
    ["'3'", "Push value 3 to the stack", "[1, 2]", "[1, 2, 3]", "[0, 0]", "[0, 0]", "''"],
    ["'4'", "Push value 4 to the stack", "[1, 2]", "[1, 2, 4]", "[0, 0]", "[0, 0]", "''"],
    ["'5'", "Push value 5 to the stack", "[1, 2]", "[1, 2, 5]", "[0, 0]", "[0, 0]", "''"],
    ["'6'", "Push value 6 to the stack", "[1, 2]", "[1, 2, 6]", "[0, 0]", "[0, 0]", "''"],
    ["'7'", "Push value 7 to the stack", "[1, 2]", "[1, 2, 7]", "[0, 0]", "[0, 0]", "''"],
    ["'8'", "Push value 8 to the stack", "[1, 2]", "[1, 2, 8]", "[0, 0]", "[0, 0]", "''"],
    ["'9'", "Push value 9 to the stack", "[1, 2]", "[1, 2, 9]", "[0, 0]", "[0, 0]", "''"],
    ["'+'", "Push (S[1] + S[0]) to the stack", "[1, 2, 3, 4]", "[1, 2, 7]", "[0, 0]", "[0, 0]", "''"],
    ["'-'", "Push (S[1] - S[0]) to the stack", "[1, 2, 3, 4]", "[1, 2, -1]", "[0, 0]", "[0, 0]", "''"],
    ["'*'", "Push (S[1] * S[0]) to the stack", "[1, 2, 3, 4]", "[1, 2, 12]", "[0, 0]", "[0, 0]", "''"],
    ["'/'", "Push (S[1] / S[0]) to the stack (integer division)", "[1, 2, 3, 2]", "[1, 2, 1]", "[0, 0]", "[0, 0]", "''"],
    ["':'", "Push -1 if S[1]<S[0]. Push 0 if S[1]=S[0]. Push 1 if S[1]>S[0]", "[1, 2, 3, 2]", "[1, 2, 1]", "[0, 0]", "[0, 0]", "''"],
    ["'p'", "Print S[0]", "[1, 2, 3, 4]", "[1, 2, 3]", "[0, 0]", "[0, 0]", "'4'"],
    ["'P'", "Print S[0] as ASCII character", "[1, 2, 97]", "[1, 2]", "[0, 0]", "[0, 0]", "'a'"],
    ["'>'", "Store S[1] into memory location S[0]", "[2, 5, 1]", "[2]", "[0, 0, 0]", "[0, 5, 0]", "''"],
    ["'<'", "Push item at memory location S[0] to the stack", "[1, 2]", "[1, 5]", "[0, 0, 5, 0]", "[0, 0, 5, 0]", "''"],
    ["'^'", "Push a duplicate of value located at position S[0] in the stack. '0^' duplicates the top value of the stack", "[1, 2, 3, 1]", "[1, 2, 3, 2]", "[0, 0]", "[0, 0]", "''"],
    ["'v'", "Remove the item at position S[0] in the stack. Push it on top of the stack. '1v' swaps the top 2 values of the stack", "[1, 2, 3, 4, 3]", "[2, 3, 4, 1]", "[0, 0]", "[0, 0]", "''"],
    ["'g'", "Add S[0] to the program counter", "???", "???", "[0, 0]", "[0, 0]", "???"],
    ["'?'", "Add S[0] to the program counter if S[1] is 0", "???", "???", "[0, 0]", "[0, 0]", "???"],
    ["'!'", "Stop the execution", "[0, 0]", "[0, 0]", "[0, 0]", "[0, 0]", "''"]
];
</script>

<style scoped>
.main-container {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.info-block {
    border: 1px solid black;
}

.text-center {
    text-align: center;
}

table,
th,
td {
    border: 1px solid black;
}

tr:hover {
    background-color: lightgray;
}

table {
    margin: 0px auto;
}

ul {
    display: inline-block;
}
</style>
